/* You can add global styles to this file, and also import other style files */
.fade.modal {
    opacity: unset!important;

}
.modal-backdrop{
    z-index:1
}
.modal-content{
    z-index:3
}

.offline-header {
    height: 45px;
    padding: 10px 0px;
    text-align: center;
}

.offline {
    padding: 95px 0 60px;
}

@media (min-width: 0px) and (max-width:636px) {
    .offline {
        padding: 145px 0 60px;
    }
}

.hide-offline-content {
    display: none;
}